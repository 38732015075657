import React, { useEffect } from "react";
import { Toaster } from "src/components/ui/toaster";
import { useToast } from "src/components/ui/use-toast";
import { useStoreState } from "src/hooks/storeHooks";

export default function ErrorToaster() {
  const errorStatus = useStoreState((state) => state.error);
  const { toast } = useToast();
  useEffect(() => {
    if (errorStatus != null) {
      toast({
        title: "Error " + errorStatus.code,
        description: errorStatus.message,
        variant: "destructive",
      });
    }
  }, [errorStatus]);

  return (
    <>
      <Toaster />
    </>
  );
}
