import { useEffect } from "react";
import { useStoreActions, useStoreState } from "./storeHooks";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import { useStoreRehydrated } from "easy-peasy";
import { useParams, usePathname } from "next/navigation";

export default function useInitialStore() {
  const isRehydrated = useStoreRehydrated();
  const params = useParams();
  const pathname = usePathname();

  // tenant
  const tenant = useStoreState((state) => state.tenant);

  // cart
  const fetchCartThunkUsingUuid = useStoreActions((state) => state.fetchCartThunkUsingUuid);
  const resetCart = useStoreActions((state) => state.resetCart);
  const storageCartUUID: string | undefined = getLocalStorage(`cart_id`);
  const paramCartUUID = pathname?.includes("cart") && typeof params?.uuid == "string" ? params.uuid : "";

  useEffect(
    function fetchAndSetInitialData() {
      // cart
      if ((storageCartUUID || paramCartUUID) && tenant) {
        const cartUUID = paramCartUUID || storageCartUUID;
        fetchCartThunkUsingUuid({ uuid: cartUUID as string, tenantId: tenant.id });
      }
    },
    [
      // To make sure the logic in fetchAndSetInitialData runs after easy peasy finish rehydration
      isRehydrated,
      tenant,
      paramCartUUID,
    ]
  );
}
