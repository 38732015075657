import useInitialStore from "@/hooks/useInitialStore";

type Props = {};

function InitialStore({}: Props) {
  useInitialStore();

  return <></>;
}

export default InitialStore;
